<template>
  <div class="service-categories">
    <div
      class="group"
      v-for="(group, k) in groups"
      v-show="group.services.length > 0"
      :key="group.id"
    >
      <GroupHeader
        :title="group.title"
        :description="group.description"
        :hideTitles="hideTitles"
        :removeTopMargin="k == 0"
        v-if="group.title || group.description"
      />
      <ServiceCarousel
        v-if="group.renderers.carousel"
        :services="group.renderers.carousel || []"
      />
      <ServiceCarousel
        v-show="group.renderers.list"
        v-for="service in group.renderers.list || []"
        :services="[service]"
        :key="service.id"
      />
      <Categories
        v-if="group.renderers.grid"
        :categories="group.renderers.grid || []"
      />
    </div>
  </div>
</template>

<script>
import { v4 } from 'uuid'
import GroupHeader from '@/components/GroupHeader'
import ServiceCarousel from '@/components/ServiceCarousel'
import Categories from '@/components/Categories'

const OPTIONS = {
  sales: { render: 'carousel', ungroup: true },
  food2: { render: 'carousel' },
  common: { render: 'grid', merge: true },
  service_desk: { render: 'grid', merge: true },
  food: { render: 'grid', merge: true },
  room_service: { render: 'grid', merge: true },
  once: { render: 'grid', merge: true },
  excursion: { render: 'grid' },
  spa: { render: 'grid' },
  booking: { render: 'grid', ungroup: true, groupInfoFromCategory: true },
  common_big: { render: 'list' },
  common_cart: { render: 'list' },
  map: { render: 'list' },
}

export default {
  props: ['categories', 'stay', 'hideTitles'],
  components: { GroupHeader, ServiceCarousel, Categories },
  data() {
    return {
      groups: [],
    }
  },
  methods: {
    updateItems() {
      const categories = JSON.parse(JSON.stringify(this.categories))

      this.groups = []
      for (const category of categories) {
        const type = category.type.toLowerCase()
        if (!OPTIONS[type]) continue

        if (!category.categoryGroup)
          category.categoryGroup = {
            id: OPTIONS[type].merge ? OPTIONS[type].render : v4(),
          }
        let group = category.categoryGroup

        if (!group.services) group.services = []
        if (!group.render) group.render = OPTIONS[type].render

        if (!this.groups.find(fGroup => fGroup.id == group.id))
          this.groups.push(group)
        group = this.groups.find(fGroup => fGroup.id == group.id)

        if (OPTIONS[type].groupInfoFromCategory) {
          group.title = category.title
          group.description = category.description
        }

        const servicesToAdd = OPTIONS[type].ungroup
          ? category.services
          : [category]
        group.services.push(...servicesToAdd)
      }
      this.groups = Object.values(this.groups).sort(
        (a, b) => a.orderNum - b.orderNum,
      )

      for (const group of this.groups) {
        if (!group.renderers) group.renderers = {}
        group.services = group.services.sort(
          (a, b) => a.categoryGroup?.orderNum - b.categoryGroup?.orderNum,
        )
        for (const service of group.services) {
          const render = OPTIONS[service.type.toLowerCase()].render
          if (!group.renderers[render]) group.renderers[render] = []
          group.renderers[render].push(service)
        }
      }
    },
  },
  watch: {
    categories: {
      immediate: true,
      deep: true,
      handler(categories) {
        if (!categories) return
        this.$nextTick(() => this.updateItems())
      },
    },
  },
}
</script>

<style lang="scss">
.service-categories {
  padding-bottom: 15px;

  > .group {
    margin-bottom: 15px;
  }
}
</style>
